import React from 'react'
import ButtonOriginal from 'component-library/components/Button'
import styled, { css } from 'styled-components'

import { injectSecondaryButtonDarkStyle } from '@components/Button/ButtonSecondaryDark'
import { withDarkTheme } from '@components/Button/withDarkTheme'
import { Body2Bold, Body3Bold } from '@typography'

import { getCommonButtonStyle } from './'
import { withLink } from './withLink'

export const ButtonSecondaryStyled = styled(ButtonOriginal)`
  ${({ isDark, theme }) => css`
    ${getCommonButtonStyle}

    border: ${theme.sizes.s1} solid ${theme.components.button.secondary
      .borderColor};

    &:before {
      border: ${theme.sizes.s1} solid transparent;
    }

    &:hover:not(.is-loading):not(.is-disabled):not(:active):not(:focus) {
      background-color: ${theme.colors.neutral0}29;
      border-color: ${theme.components.button.secondary.borderHoverColor};

      :before {
        border: ${theme.sizes.s1} solid transparent;
      }
    }

    &:active:not(.is-disabled):not(.is-loading) {
      background-color: ${theme.colors.neutral100}14;
      border-color: ${theme.components.button.secondary.borderPressedColor};

      :before {
        border: ${theme.sizes.s1} solid transparent;
      }
    }

    &:focus:not(:active):not(.is-loading) {
      outline: ${theme.sizes.s1} solid
        ${theme.components.button.primary.borderFocusColor};
      outline-offset: ${theme.sizes.s1};

      :before {
        border: ${theme.sizes.s1} solid transparent;
      }
    }

    &.is-loading {
      background-color: transparent;
    }

    & > ${Body2Bold}, & > ${Body3Bold} {
      color: ${theme.colors.neutral100};
    }

    ${isDark && injectSecondaryButtonDarkStyle}
  `}
`

const ButtonSecondary = ({ children, small, ...props }) => (
  <ButtonSecondaryStyled
    aria-label={children && children.toString()}
    mode='secondary'
    small={small}
    {...props}
  >
    {small ? (
      <Body3Bold>{children}</Body3Bold>
    ) : (
      <Body2Bold>{children}</Body2Bold>
    )}
  </ButtonSecondaryStyled>
)

export default styled(withDarkTheme(withLink(ButtonSecondary)))``
