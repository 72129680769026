import Breakpoints from '../theme/Breakpoints'

const getResponsivePlatform = (width) => {
  if (
    width < Breakpoints.tablet.maxBreakpoint &&
    width >= Breakpoints.tablet.breakpoint
  ) {
    return 'tablet'
  } else if (width < Breakpoints.mobile.maxBreakpoint) {
    return 'mobile'
  } else {
    return 'desktop'
  }
}

export { getResponsivePlatform }
