function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
import styled from "styled-components";
import { borders, display, margins, paddings, sizes } from "../../helpers";
const Div = styled.div`
  ${borders};
  ${display};
  ${margins};
  ${paddings};
  ${sizes};
`;
Div.propTypes = _extends({}, borders.propTypes, display.propTypes, margins.propTypes, paddings.propTypes, sizes.propTypes);
export default Div;
