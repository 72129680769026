import styled, { css } from "styled-components";
import { resetCSS } from "../../../helpers";
const mainCSS = css`
  position: absolute;
  max-width: ${({ noWrap })=>noWrap ? 'auto' : '175px'};
  width: max-content;
  border-radius: ${({ theme })=>theme.globals.borderRadius.borderRadius1};
  background-color: ${({ theme })=>theme.components.tooltip.background};
  box-shadow: ${({ theme })=>theme.globals.shadows.shadow2};
  padding: ${({ theme })=>theme.sizes.s3};

  /* Edge: 'max-content' doesn't work properly, hacking stuff */
  _:-ms-lang(x),
  _::-webkit-meter-bar,
  & {
    display: table;
  }
`;
const mainTopCSS = css`
  top: 0;
  margin-top: -6px;
`;
const mainTopAfterCSS = css`
  top: 100%;
  margin-top: -6px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;
const mainBottomAfterCSS = css`
  bottom: 100%;
  margin-bottom: -6px;
  box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
`;
const mainBottomCSS = css`
  bottom: 0;
  margin-bottom: -6px;
`;
const arrow = css`
  content: ' ';
  display: flex;
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${({ theme })=>theme.components.tooltip.background};
  transform: rotate(45deg);
  border-radius: 1px;
`;
const top = css`
  ${mainCSS};
  ${mainTopCSS};
  left: 50%;
  transform: translateX(-50%) translateY(-100%) translateY(-4px);
  &:after {
    ${arrow};
    ${mainTopAfterCSS};
    left: 50%;
    margin-left: -5px;
  }
`;
const topRight = css`
  ${mainCSS};
  ${mainTopCSS};
  transform: translateY(-100%) translateY(-4px);

  &:after {
    ${arrow};
    ${mainTopAfterCSS};
    left: 10px;
  }
`;
const topLeft = css`
  ${mainCSS};
  ${mainTopCSS};
  left: 100%;

  transform: translateX(-100%) translateY(-100%) translateY(-4px);
  &:after {
    ${arrow};
    ${mainTopAfterCSS};
    right: 10px;
  }
`;
const bottom = css`
  ${mainCSS};
  ${mainBottomCSS};
  left: 50%;
  transform: translateX(-50%) translateY(100%) translateY(4px);
  &:after {
    ${arrow};
    ${mainBottomAfterCSS} left: 50%;
    margin-left: -5px;
  }
`;
const bottomRight = css`
  ${mainCSS};
  ${mainBottomCSS};
  transform: translateY(100%) translateY(4px);

  &:after {
    ${arrow};
    ${mainBottomAfterCSS} left: 10px;
  }
`;
const bottomLeft = css`
  ${mainCSS};
  ${mainBottomCSS};
  left: 100%;
  transform: translateX(-100%) translateY(100%) translateY(4px);
  &:after {
    ${arrow};
    ${mainBottomAfterCSS};
    right: 10px;
  }
`;
const left = css`
  ${mainCSS};
  margin-left: -16px;
  left: 0;
  transform: translateX(-100%) translateX(4px) translateY(-50%);
  &:after {
    ${arrow};
    left: 100%;
    bottom: calc(50% - 5px);
    margin-left: -6px;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
  }
`;
const right = css`
  ${mainCSS};
  margin-left: 8px;
  transform: translateX(4px) translateY(-50%);
  &:after {
    ${arrow};
    right: 100%;
    bottom: calc(50% - 5px);
    margin-right: -6px;
    box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
  }
`;
const positionsCSS = {
    bottom,
    bottomLeft,
    bottomRight,
    left,
    right,
    top,
    topLeft,
    topRight
};
const Container = styled.div.attrs(resetCSS)`
  ${({ direction = 'top' })=>positionsCSS[direction]};
`;
export default Container;
