import { hexToRGB } from "../../../helpers/color.js";
import { globals } from "..";
const darkComponents = ({ colors: c, globals: g = globals })=>({
        accordion: {
            background: c.neutral70,
            backgroundHover: c.neutral60,
            backgroundPressed: c.neutral70,
            border: c.neutral80,
            icon: c.neutral0,
            iconPressed: c.pw30,
            iconLocked: c.neutral0,
            label: c.neutral0,
            labelPressed: c.pw30
        },
        button: {
            dropdown: {
                background: c.transparent,
                backgroundFocus: c.transparent,
                backgroundHover: c.transparent,
                backgroundLoading: c.transparent,
                backgroundPressed: c.transparent,
                border: c.neutral20,
                borderHover: c.neutral0,
                borderFocus: c.pw50,
                borderPressed: c.pw50,
                icon: c.neutral0,
                loader: c.neutral0,
                text: c.neutral0,
                textFocus: c.neutral0,
                textHover: c.neutral0,
                textPressed: c.neutral0
            },
            icon: {
                background: c.transparent,
                backgroundFocus: c.pw60,
                backgroundHover: c.neutral60,
                backgroundLoading: c.neutral60,
                backgroundPressed: c.pw60,
                border: c.neutral0,
                loader: c.neutral0,
                text: c.neutral20,
                textFocus: c.pw30,
                textHover: c.neutral0,
                textPressed: c.pw30
            },
            primary: {
                background: c.pw50,
                backgroundFocus: c.pw40,
                backgroundHover: c.pw40,
                backgroundLoading: c.pw50,
                backgroundPressed: c.pw60,
                borderFocusWidth: g.borderWidth.borderWidth0,
                borderHoverWidth: g.borderWidth.borderWidth0,
                borderPressedWidth: g.borderWidth.borderWidth0,
                borderWidth: g.borderWidth.borderWidth0,
                hidden: c.transparent,
                loader: c.neutral0,
                shadowPressed: `0 0 1px 2px ${c.pw20}`,
                text: c.neutral0,
                textFocus: c.neutral0,
                textHover: c.neutral0,
                textPressed: c.neutral0
            },
            secondary: {
                background: c.transparent,
                backgroundFocus: hexToRGB(c.neutral0, 0.2),
                backgroundHover: hexToRGB(c.neutral0, 0.1),
                backgroundLoading: hexToRGB(c.neutral0, 0.2),
                backgroundPressed: hexToRGB(c.neutral0, 0.2),
                borderColor: c.neutral0,
                borderFocusColor: c.neutral0,
                borderHoverColor: c.neutral0,
                borderLoadingColor: c.neutral0,
                borderPressedColor: c.neutral0,
                text: c.neutral0,
                textFocus: c.neutral0,
                textHover: c.neutral0,
                textPressed: c.neutral0
            },
            tertiary: {
                background: c.transparent,
                backgroundFocus: c.neutral60,
                backgroundHover: c.neutral60,
                backgroundLoading: c.neutral60,
                backgroundPressed: c.neutral60,
                border: c.neutral0,
                loader: c.neutral0,
                text: c.neutral20,
                textFocus: c.neutral0,
                textHover: c.neutral0,
                textPressed: c.neutral0
            },
            toggle: {
                background: c.neutral70,
                backgroundActive: c.pw60,
                backgroundFocus: c.neutral60,
                backgroundHover: c.neutral60,
                backgroundPressed: c.neutral60,
                border: c.neutral60,
                borderActive: c.neutral60,
                borderFocus: c.neutral60,
                borderHover: c.neutral60,
                borderPressed: c.neutral60,
                icon: c.neutral20,
                iconActive: c.neutral0,
                iconFocus: c.neutral0,
                iconHover: c.neutral0,
                iconPressed: c.neutral0,
                text: c.neutral20,
                textActive: c.pw30,
                textFocus: c.neutral0,
                textHover: c.neutral0,
                textPressed: c.neutral0
            }
        },
        buttonDropdown: {
            primary: {
                icon: c.neutral0,
                divider: c.pw40
            },
            secondary: {
                icon: c.neutral0
            }
        },
        checkbox: {
            background: c.neutral80,
            border: c.neutral20,
            borderError: c.lightblood,
            borderFocus: c.pw60,
            borderHover: c.neutral0
        },
        input: {
            background: c.neutral80,
            border: c.neutral20,
            borderError: c.lightblood,
            borderFocus: c.pw50,
            borderHover: c.neutral0,
            icon: c.neutral20,
            iconCopy: c.neutral20,
            iconCopyHover: c.neutral0,
            loader: c.neutral0,
            placeholder: c.neutral20,
            text: c.neutral0
        },
        inputNumber: {
            background: c.neutral80,
            border: c.neutral0,
            borderError: c.lightblood,
            borderFocus: c.neutral20,
            borderHover: c.neutral20,
            iconContainer: {
                icon: c.neutral0,
                iconHover: c.neutral0,
                iconFocus: c.neutral60,
                backgroundHover: c.neutral60,
                backgroundActive: c.neutral60,
                borderColor: c.neutral40
            },
            placeholder: c.neutral30,
            text: c.neutral0
        },
        label: {
            background: c.neutral60,
            backgroundHover: c.neutral60,
            icon: c.neutral20,
            iconHover: c.neutral0,
            text: c.neutral20,
            textHover: c.neutral0
        },
        loader: {
            background: c.transparent,
            color: c.pw30,
            colorSecondary: c.neutral0
        },
        phoneNumber: {
            error: {
                icon: c.lightblood,
                text: c.lightblood
            },
            input: {
                background: c.neutral70,
                placeholder: c.neutral20,
                text: c.neutral0
            },
            borderer: {
                border: c.neutral20,
                borderError: c.lightblood,
                borderFocus: c.pw50
            }
        },
        searchBar: {
            border: c.neutral20,
            background: c.neutral80,
            icon: c.neutral20,
            borderHover: c.neutral0,
            backgroundHover: c.neutral80,
            iconHover: c.neutral0,
            borderFocus: c.pw50,
            backgroundFocus: c.neutral80,
            iconFocus: c.neutral0,
            text: c.neutral0,
            placeholder: c.neutral20,
            loader: c.neutral20,
            iconClear: c.neutral20,
            iconClearHover: c.neutral0
        },
        select: {
            background: c.neutral80,
            backgroundHover: c.neutral80,
            backgroundFocus: c.neutral80,
            border: c.neutral20,
            borderError: c.lightblood,
            borderFocus: c.pw50,
            borderHover: c.neutral0,
            icon: c.neutral0,
            loader: c.neutral0,
            placeholder: c.neutral20,
            selectedText: c.neutral0,
            text: c.neutral0,
            textPressed: c.neutral0
        },
        scrollbar: {
            background: c.scrollbardark,
            backgroundHover: c.scrollbardarkHover
        },
        switch: {
            backgroundOn: c.pw50,
            backgroundOff: c.neutral10
        },
        tag: {
            text: c.neutral0,
            icon: c.neutral0
        },
        textArea: {
            background: c.neutral80,
            border: c.neutral20,
            borderError: c.lightblood,
            borderFocus: c.pw50,
            borderHover: c.neutral0,
            placeholder: c.neutral20,
            text: c.neutral0
        },
        typography: {
            body1: {
                text: c.neutral0
            },
            body1Medium: {
                text: c.neutral0
            },
            body2: {
                text: c.neutral0
            },
            body2Medium: {
                text: c.neutral0
            },
            caption: {
                text: c.neutral0
            },
            captionMedium: {
                text: c.neutral0
            },
            header1: {
                text: c.neutral0
            },
            header2: {
                text: c.neutral0
            },
            header3: {
                text: c.neutral0
            },
            header4: {
                text: c.neutral0
            },
            header4Medium: {
                text: c.neutral0
            },
            paragraph: {
                text: c.neutral0
            },
            smallText: {
                text: c.neutral0
            },
            smallTextBold: {
                text: c.neutral0
            },
            subSubTitle: {
                text: c.neutral0
            },
            subTitle: {
                text: c.neutral0
            },
            textLabel: {
                text: c.neutral0
            },
            title: {
                text: c.neutral0
            }
        }
    });
export default darkComponents;
