const Breakpoints = {
  mobile: {
    breakpoint: 0,
    containerMaxWidth: '100%',
    containerPadding: '0 s4',
    maxBreakpoint: 767,
    width: 'auto',
  },
  bigMobile: {
    breakpoint: 740,
    containerMaxWidth: '100%',
    containerPadding: '0 s4',
    maxBreakpoint: 767,
    width: 'auto',
  },
  tablet: {
    breakpoint: 768,
    containerMaxWidth: '880px',
    containerPadding: '0 s5',
    maxBreakpoint: 1119,
    width: 'auto',
  },
  bigTablet: {
    breakpoint: 1100,
    containerMaxWidth: '880px',
    containerPadding: '0 s5',
    maxBreakpoint: 1119,
    width: 'auto',
  },
  desktop: {
    breakpoint: 1120,
    containerMaxWidth: '1232px',
    containerPadding: '0 s5',
    width: 'auto',
  },
}

export default Breakpoints
