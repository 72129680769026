const globals = {
    borderRadius: {
        bR1: '3px',
        borderRadius1: '3px',
        bR3: '8px',
        borderRadius3: '8px',
        bRCircle: '50%',
        borderRadiusCircle: '50%'
    },
    borderStyle: {
        solid: 'solid'
    },
    borderWidth: {
        bw0: '0px',
        borderWidth0: '0px',
        bW1: '1px',
        borderWidth1: '1px',
        bW2: '2px',
        borderWidth2: '2px',
        bW3: '4px',
        borderWidth3: '4px',
        bW4: '8px',
        borderWidth4: '8px',
        bW5: '3px',
        borderWidth5: '3px'
    },
    cssClearer: {
        global: false,
        nav: true
    },
    imageSize: {
        imageSize1: {
            width: '300px',
            height: '200px'
        },
        imageSize2: {
            width: '96px',
            height: '96px'
        },
        imageSize3: {
            width: '80px',
            height: '80px'
        }
    },
    panelSize: {
        small: '264px',
        medium: '296px',
        large: '456px'
    },
    placeholderShape: {
        rect: {
            height: '8px',
            width: 'inherit'
        },
        square: {
            height: '32px',
            width: '32px'
        }
    },
    opacity: {
        o0: 0.0,
        o1: 0.4,
        o2: 0.8,
        o3: 1.0
    },
    shadows: {
        shadow1: '0 1px 2px rgba(0, 0, 0, 0.2)',
        shadow2: '0 1px 4px rgba(0, 0, 0, 0.2)',
        shadow3: '0 1px 10px rgba(0, 0, 0, 0.2)',
        shadow4: '0 1px 20px rgba(0, 0, 0, 0.2)'
    }
};
export default globals;
