import * as React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { borders, display, isKeyOf, margins, paddings } from "../../helpers";
const calculatePaddings = (p, sizes)=>{
    if (!p) {
        return p;
    }
    const arrayPaddings = p.split(/\s+/g);
    if (arrayPaddings.length > 1) {
        return arrayPaddings.map((padding)=>`${isKeyOf(padding, sizes.paddings) && sizes.paddings[padding] || isKeyOf(padding, sizes) && sizes[padding] || padding}`).join(' ');
    }
    const padding = arrayPaddings[0];
    return `${isKeyOf(padding, sizes.paddings) && sizes.paddings[padding] || isKeyOf(padding, sizes) && sizes[padding] || padding}`;
};
const responsiveRules = (theme, noPadding)=>{
    const { responsive, sizes } = theme;
    return Object.keys(responsive).reduce((rules, size)=>{
        const { breakpoint, maxBreakpoint, width } = isKeyOf(size, responsive) ? responsive[size] : {
            breakpoint: 0,
            maxBreakpoint: null,
            width: null
        };
        const mediaQuery = `@media screen and (min-width: ${breakpoint}px) ${maxBreakpoint ? `and (max-width: ${maxBreakpoint}px)` : ''}`;
        return rules.concat(css`
      ${mediaQuery} {
        max-width: ${isKeyOf(size, responsive) ? responsive[size].containerMaxWidth : 'inherit'};
        padding: ${noPadding ? '0px' : calculatePaddings(isKeyOf(size, responsive) ? responsive[size].containerPadding : 'inherit', sizes)};
        ${width && `width: ${width};`}
      }
    `);
    }, []);
};
const Container = styled.div`
  margin: 0 auto;

  ${({ noPadding = false, theme })=>responsiveRules(theme, noPadding)};

  ${borders};
  ${display};
  ${margins};
  ${paddings};

  width: 100%;
`;
Container.defaultProps = {
    noPadding: false
};
Container.propTypes = {
    noPadding: PropTypes.bool
};
export default Container;
