import styled from "styled-components";
import { resetCSS } from "../../../helpers";
import commonStyle from "../helpers/commonStyle.js";
const Header3 = styled.h3.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme })=>color ? theme.colors[color] || color : theme.components.typography.header3.text};
  font-family: ${({ theme })=>theme.fonts.header3.fontFamily};
  font-size: ${({ theme })=>theme.fonts.header3.fontSize};
  font-weight: ${({ theme })=>theme.fonts.header3.fontWeight};
  line-height: ${({ theme })=>theme.fonts.header3.lineHeight};
`;
export default Header3;
