import styled from "styled-components";
import { resetCSS } from "../../../helpers";
import commonStyle from "../helpers/commonStyle.js";
const Header2 = styled.h2.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme })=>color ? theme.colors[color] || color : theme.components.typography.header2.text};
  font-family: ${({ theme })=>theme.fonts.header2.fontFamily};
  font-size: ${({ theme })=>theme.fonts.header2.fontSize};
  font-weight: ${({ theme })=>theme.fonts.header2.fontWeight};
  line-height: ${({ theme })=>theme.fonts.header2.lineHeight};
`;
export default Header2;
