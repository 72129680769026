import styled, { css } from "styled-components";
import { StyledIcon } from "../../../icons";
import genericButtonStyle from "../helpers/genericButtonStyle.js";
import RoundNotification from "../../RoundNotification";
import { Body2Medium } from "../../Typography";
import StyledImage from "./StyledImage.js";
const buttonStyle = ({ deletion, disabled, isLoading, theme })=>{
    const themeObject = deletion ? theme.components.button.icon.deletion : theme.components.button.icon;
    return css`
    box-sizing: border-box;
    fill: ${themeObject.text};
    background-color: ${themeObject.background};

    ${StyledIcon} {
      box-sizing: content-box;
      cursor: ${disabled || isLoading ? 'not-allowed' : 'pointer'};
      padding: ${theme.sizes.s1};
    }

    ${RoundNotification} {
      box-shadow: 0 0 0 ${theme.sizes.s1} ${theme.colors.snow};
      cursor: pointer;
      position: absolute;
      right: ${theme.sizes.s2};
      top: ${theme.sizes.s2};

      &::before {
        background-color: ${theme.colors.transparent};
        border-radius: 50%;
        box-shadow: 0 0 0 ${theme.sizes.s1} ${theme.components.button.icon.background};
        content: '';
        height: ${theme.sizes.s3};
        position: absolute;
        right: 0;
        top: 0;
        width: ${theme.sizes.s3};
      }
    }

    &:hover:not(.is-loading):not(.is-disabled) {
      fill: ${themeObject.textHover};
      background-color: ${themeObject.backgroundHover};

      ${RoundNotification} {
        &::before {
          box-shadow: 0 0 0 ${theme.sizes.s1} ${theme.components.button.icon.backgroundHover};
        }
      }
    }

    &:active:not(.is-disabled):not(.is-loading) {
      &:before {
        border: 0;
      }
      fill: ${themeObject.textPressed};
      background-color: ${themeObject.backgroundPressed};

      ${RoundNotification} {
        &::before {
          box-shadow: 0 0 0 ${theme.sizes.s1} ${theme.components.button.icon.backgroundPressed};
        }
      }
    }

    &.is-loading {
      &:before {
        border: 0;
      }

      fill: ${themeObject.backgroundLoading};
      background-color: ${themeObject.backgroundLoading};

      // Loader colors.
      > * {
        border-color: ${themeObject.loader};
        border-top-color: ${themeObject.backgroundLoading};
      }
    }

    ${StyledIcon}, ${StyledImage} {
      + * {
        margin-left: ${theme.sizes.s3};
      }
    }
  `;
};
const focusProperties = ({ deletion, theme })=>deletion ? css`` : css`
        &:focus:not(:active):not(.is-loading) {
          fill: ${theme.components.button.icon.textFocus};
          background-color: ${theme.components.button.icon.backgroundFocus};

          ${RoundNotification} {
            &::before {
              box-shadow: 0 0 0 ${theme.sizes.s1} ${theme.components.button.icon.backgroundFocus};
            }
          }
        }
      `;
const IconButton = styled(Body2Medium)`
  ${genericButtonStyle};

  padding: ${({ $small, theme })=>$small ? theme.sizes.s2 : theme.sizes.s3};
  min-width: 0;

  ${(props)=>buttonStyle(props)};
  ${(props)=>focusProperties(props)};
`;
export default IconButton;
