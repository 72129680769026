const sizes = {
    heights: {
        height0: '1px',
        h0: '1px',
        height1: '2px',
        h1: '2px',
        height2: '4px',
        h2: '4px',
        height3: '8px',
        h3: '8px',
        height4: '16px',
        h4: '16px',
        height5: '24px',
        h5: '24px',
        height6: '32px',
        h6: '32px',
        height7: '40px',
        h7: '40px',
        height8: '48px',
        h8: '48px',
        height9: '56px',
        h9: '56px',
        height10: '64px',
        h10: '64px',
        height11: '128px',
        h11: '128px',
        height12: '12px',
        h12: '12px',
        height13: '20px',
        h13: '20px',
        height14: '36px',
        h14: '36px'
    },
    paddings: {
        p1: '2px',
        p2: '4px',
        p3: '8px',
        p4: '16px',
        p5: '24px',
        p6: '32px',
        p7: '40px',
        p8: '48px',
        p9: '56px',
        p10: '64px',
        p11: '128px',
        p12: '12px',
        p13: '20px',
        p14: '72px',
        p15: '80px',
        p16: '88px',
        p17: '96px'
    },
    margins: {
        m1: '2px',
        m2: '4px',
        m3: '8px',
        m4: '16px',
        m5: '24px',
        m6: '32px',
        m7: '40px',
        m8: '48px',
        m9: '56px',
        m10: '64px',
        m11: '128px',
        m12: '12px',
        m13: '20px',
        m14: '72px',
        m15: '80px',
        m16: '88px',
        m17: '96px'
    },
    sizes: {
        size0: '1px',
        s0: '1px',
        size1: '2px',
        s1: '2px',
        size2: '4px',
        s2: '4px',
        size3: '8px',
        s3: '8px',
        size4: '16px',
        s4: '16px',
        size5: '24px',
        s5: '24px',
        size6: '32px',
        s6: '32px',
        size7: '40px',
        s7: '40px',
        size8: '48px',
        s8: '48px',
        size9: '56px',
        s9: '56px',
        size10: '64px',
        s10: '64px',
        size11: '128px',
        s11: '128px',
        size12: '12px',
        s12: '12px'
    },
    widths: {
        width0: '1px',
        w0: '1px',
        width1: '2px',
        w1: '2px',
        width2: '4px',
        w2: '4px',
        width3: '8px',
        w3: '8px',
        width4: '16px',
        w4: '16px',
        width5: '24px',
        w5: '24px',
        width6: '32px',
        w6: '32px',
        width7: '40px',
        w7: '40px',
        width8: '48px',
        w8: '48px',
        width9: '56px',
        w9: '56px',
        width10: '64px',
        w10: '64px',
        width11: '128px',
        w11: '128px',
        width12: '12px',
        w12: '12px',
        width13: '20px',
        w13: '20px',
        width14: '72px',
        w14: '72px',
        width16: '88px',
        w16: '88px'
    },
    s0: '1px',
    s1: '2px',
    s2: '4px',
    s3: '8px',
    s4: '16px',
    s5: '24px',
    s6: '32px',
    s7: '40px',
    s8: '48px',
    s9: '56px',
    s10: '64px',
    s11: '128px',
    s12: '12px',
    s13: '20px',
    s14: '72px',
    s15: '80px',
    s16: '88px',
    s17: '96px',
    s18: '36px',
    s19: '256px',
    s20: '6px'
};
export default sizes;
