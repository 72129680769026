import React from 'react'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { OnlyDesktop, OnlyTabletAndMobile } from '@styles/DisplayOnMedia'
import { StyledLink } from '@styles/StyledLink'
import { Body2Bold, Body3Bold } from '@typography'
import { styleForTablet, styleForTabletAndMobile } from '@utils'

import CTAPrimary from './CTAPrimary'
import CTASecondary from './CTASecondary'

const Container = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;

    & > :not(:first-child) {
      margin-left: ${theme.sizes.s3};
    }

    & > :first-child {
      margin-right: ${theme.sizes.s4};
      max-width: max-content;
    }

    ${CTAPrimary} span svg,
    ${CTASecondary} span svg {
      display: none;
    }

    ${styleForTablet} {
      margin-top: auto;
    }

    ${styleForTabletAndMobile} {
      flex-direction: column;
    }

  `}
`

const RightMenuContent = ({ rightMenuContent, ...props }) => (
  <Container {...props}>
    {map(rightMenuContent, ({ link, label }, index) => {
      const key = `${label}-${index}`

      switch (index) {
        case 0:
          return (
            <StyledLink className='login' key={key} underline to={link}>
              <OnlyDesktop>
                <Body3Bold>{label}</Body3Bold>
              </OnlyDesktop>
              <OnlyTabletAndMobile>
                <Body2Bold>{label}</Body2Bold>
              </OnlyTabletAndMobile>
            </StyledLink>
          )
        case 1:
          return (
            <CTASecondary
              id='secondary'
              key={key}
              small
              to={link}
              noShadow
              eventName={'navigation_signup_click'}
            >
              {label}
            </CTASecondary>
          )
        case 2:
          return (
            <CTAPrimary
              id='primary'
              key={key}
              small
              to={link}
              noShadow
              eventName={'navigation_signup_click'}
            >
              {label}
            </CTAPrimary>
          )
      }
    })}
  </Container>
)

RightMenuContent.propTypes = {
  rightMenuContent: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default styled(RightMenuContent)``
