import { css } from "styled-components";
export default (({ b_b, bC = 'smoke', bR = 'bR1', bS = 'solid', bW = 'bW1', b_l, b_r, b_t, theme })=>{
    if (b_b || b_l || b_r || b_t || bR) {
        const borderColor = theme.colors[bC] || bC;
        const borderRadius = theme.globals.borderRadius[bR] || bR;
        const borderStyle = bS;
        const borderWidth = theme.globals.borderWidth[bW] || bW;
        const fullBorder = `${borderWidth} ${borderStyle} ${borderColor}`;
        if (b_b && b_l && b_r && b_t) {
            return css`
        border: ${fullBorder};
        border-radius: ${borderRadius};
      `;
        }
        return css`
      ${b_b && `border-bottom: ${fullBorder}`};
      ${b_l && `border-left: ${fullBorder}`};
      ${b_r && `border-right: ${fullBorder}`};
      ${b_t && `border-top: ${fullBorder}`};
      border-radius: ${borderRadius};
    `;
    }
    return css``;
});
