import React from 'react'

const WarningSVG = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
      clipRule="evenodd" 
      d="M8 4C8.55228 4 9 4.44772 9 5L9 8C9 8.55228 8.55228 9 8 9C7.44772 9 7 8.55228 7 8V5C7 4.44772 7.44772 4 8 4Z" 
      fill="#EB5454"
      fillRule="evenodd" 
    />
    <path 
      d="M9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11Z" 
      fill="#EB5454"
    />
    <path 
      clipRule="evenodd" 
      d="M4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0L11 0C11.2652 0 11.5196 0.105357 11.7071 0.292893L15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5V11C16 11.2652 15.8946 11.5196 15.7071 11.7071L11.7071 15.7071C11.5196 15.8946 11.2652 16 11 16L5 16C4.73478 16 4.48043 15.8946 4.29289 15.7071L0.292893 11.7071C0.105357 11.5196 0 11.2652 0 11L0 5C0 4.73478 0.105357 4.48043 0.292893 4.29289L4.29289 0.292893ZM5.41421 2L2 5.41421L2 10.5858L5.41421 14L10.5858 14L14 10.5858L14 5.41421L10.5858 2L5.41421 2Z" 
      fill="#EB5454"
      fillRule="evenodd" 
    />
  </svg>
)

export default WarningSVG
