import styled, { css } from "styled-components";
import { Body2Medium } from "../../Typography";
import genericButtonStyle from "../helpers/genericButtonStyle.js";
const buttonStyle = ({ deletion, theme })=>{
    const themeObject = deletion ? theme.components.button.deletion : theme.components.button.primary;
    return css`
    &:before {
      content: ' ';
      position: absolute;
      border: ${themeObject.borderWidth} ${themeObject.borderStyle} ${themeObject.borderColor};
      border-radius: ${themeObject.borderRadius};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    color: ${themeObject.text};
    fill: ${themeObject.text};
    background: ${themeObject.background};

    &:active:not(.is-disabled):not(.is-loading) {
      &:before {
        border: ${themeObject.borderPressedWidth} ${themeObject.borderPressedStyle}
          ${themeObject.borderPressedColor};
      }
      color: ${themeObject.textPressed};
      fill: ${themeObject.textPressed};
      background: ${themeObject.backgroundPressed};
    }

    &:hover:not(.is-loading):not(.is-disabled):not(:active) {
      &:before {
        border: ${themeObject.borderHoverWidth} ${themeObject.borderHoverStyle}
          ${themeObject.borderHoverColor};
      }
      color: ${themeObject.textHover};
      fill: ${themeObject.textHover};
      background: ${themeObject.backgroundHover};
    }

    &.is-loading {
      &:before {
        border: 0;
      }

      color: ${themeObject.hidden};
      background: ${themeObject.backgroundLoading};

      // Loader colors.
      > * {
        border-color: ${themeObject.loader};
        border-top-color: ${themeObject.hidden};
      }
    }
  `;
};
const focusProperties = ({ deletion, theme })=>deletion ? css`` : css`
        &:focus:not(:active):not(.is-loading) {
          &:before {
            border: ${theme.components.button.primary.borderFocusWidth}
              ${theme.components.button.primary.borderFocusStyle}
              ${theme.components.button.primary.borderFocusColor};
          }
          color: ${theme.components.button.primary.textFocus};
          fill: ${theme.components.button.primary.textFocus};
          background: ${theme.components.button.primary.backgroundFocus};
          box-shadow: ${theme.components.button.primary.shadowPressed};
        }
      `;
const PrimaryButton = styled(Body2Medium.withComponent('button'))`
  ${genericButtonStyle};
  ${(props)=>buttonStyle(props)};
  ${(props)=>focusProperties(props)};
`;
export default PrimaryButton;
