import React from 'react'
import styled from 'styled-components'

import { ButtonSecondaryStyled } from '@components/Button/ButtonSecondary'
import { withLink } from '@components/Button/withLink'
import { Body3Bold } from '@typography'

const CTASecondary = ({ children, small, to, ...props }) => (
  <ButtonSecondaryStyled
    aria-label={children && children.toString()}
    mode='secondary'
    small={small}
    {...props}
  >
    <Body3Bold as='span'>{children}</Body3Bold>
  </ButtonSecondaryStyled>
)

export default styled(withLink(CTASecondary))``
