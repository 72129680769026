import styled from "styled-components";
import { CaptionMedium } from "../../Typography";
const TooltipMessage = styled(CaptionMedium)`
  display: block;
  position: relative;
  margin: 0;
  text-align: center;
  word-wrap: break-word;
  white-space: pre-wrap;

  /* Edge: since the max-content does not work on the container, the words don't break properly  */
  _:-ms-lang(x),
  _::-webkit-meter-bar,
  & {
    word-wrap: normal;
    word-break: normal;
    text-align: center;
  }

  z-index: 1;
  color: ${({ theme })=>theme.components.tooltip.text};
`;
export default TooltipMessage;
