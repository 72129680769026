import styled, { css } from "styled-components";
const getTransition = ({ isActive, theme })=>{
    const { easing, velocities } = theme.animations;
    return css`
    transition-property: opacity, transform;
    transition-duration: ${isActive ? velocities.v1 : velocities.v2};
    transition-timing-function: ${isActive ? easing.decelerate : easing.accelerate};
    transition-delay: 0s;
  `;
};
const getTranslate = ({ direction = '', isActive, theme })=>{
    const { moves } = theme.animations;
    const isNegative = direction.indexOf('-') >= 0;
    const isHorizontal = direction.indexOf('x') >= 0;
    const isVertical = direction.indexOf('y') >= 0;
    const getValue = ()=>{
        if (isActive) {
            return 0;
        }
        return isNegative ? `-${moves[direction.replace('-', '')]}` : moves[direction];
    };
    return css`
    transform: ${isHorizontal ? `translateX(${getValue()})` : isVertical ? `translateY(${getValue()})` : null};
  `;
};
const AnimContainer = styled('div')`
  ${({ isActive })=>`
    opacity: ${isActive ? 1 : 0};
  `}
  ${getTranslate};

  ${getTransition};
`;
export default AnimContainer;
