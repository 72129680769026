import styled, { keyframes } from "styled-components";
import { resetCSS } from "../../../helpers";
import { sizes } from "../../styles";
const LARGE = 'large';
const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const getColor = ({ mode = 'primary', theme })=>{
    const { loader } = theme.components;
    switch(mode){
        case 'primary':
        case 'p':
            return loader.color;
        case 'secondary':
        case 's':
            return loader.colorSecondary;
        default:
            return loader.color;
    }
};
const getSize = ({ size = sizes.s4, theme })=>{
    const { sizes: sizes1 } = theme;
    if (size === LARGE) {
        return sizes1.s7 || size;
    }
    return sizes1.sizes[size] || sizes1[size] || size;
};
const getBorderSize = ({ size = sizes.s4, theme })=>{
    const { bW2, bW5 } = theme.globals.borderWidth;
    if (size === LARGE) {
        return bW5;
    }
    return bW2;
};
const StyledLoader = styled.div.attrs(resetCSS)`
  animation: ${spin} 1s linear infinite;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: ${getSize};
  width: ${getSize};
  ${({ color, mode = 'primary', size = sizes.s4, theme })=>`
    border: ${getBorderSize({
        size,
        theme
    })} solid
      ${color ? theme.colors[color] || color : getColor({
        mode,
        theme
    })};
    border-top: ${getBorderSize({
        size,
        theme
    })} solid
      ${theme.components.loader.background};
  `}
`;
export default StyledLoader;
