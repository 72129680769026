function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
import { merge } from "lodash";
import { hexToRGB, lightenDarkenColor } from "../../../helpers/color.js";
const colorsVariationsFactory = ({ colors })=>{
    const lDColors = lightenDarkenColors({
        colors
    });
    const statsColors = statisticsColors({
        colors
    });
    return _extends({}, colors, lDColors, statsColors, alphaColors({
        colors: merge({}, lDColors, colors)
    }));
};
const lightenDarkenColors = ({ colors })=>({
        lightsteel: lightenDarkenColor(colors.steel, 5),
        darksteel: lightenDarkenColor(colors.steel, -10),
        blacksteel: lightenDarkenColor(colors.steel, -15),
        smoke: lightenDarkenColor(colors.lightsmoke, -5),
        darksmoke: lightenDarkenColor(colors.lightsmoke, -10),
        cloudgrey: lightenDarkenColor(colors.snow, -10),
        darkgrey: lightenDarkenColor(colors.grey, -20),
        lightgrey: lightenDarkenColor(colors.grey, 10),
        lightlagoon: colors.pw50,
        bee2: colors.pw50,
        bee3: colors.pw70
    });
const alphaColors = ({ colors })=>({
        overlay: hexToRGB(colors.blacksteel, 0.6),
        scrollbarlight: hexToRGB(colors.darksmoke, 0.4),
        scrollbarlightHover: hexToRGB(colors.darksmoke, 0.8),
        scrollbardark: hexToRGB(colors.steel, 0.4),
        scrollbardarkHover: hexToRGB(colors.steel, 0.8),
        softlagoon: colors.pw20,
        softmarine: hexToRGB(colors.marine, 0.1)
    });
const statisticsColors = ({ colors })=>({
        openedPristine: colors.pw70,
        typo: colors.pw50,
        supprUnsub: colors.pw40,
        supprBounced: colors.neutral50,
        recycled: colors.neutral40,
        unsubscribed: colors.mg50,
        spam: colors.mg40,
        blocked: colors.or50,
        failed: colors.or40,
        permanentFail: colors.ye60,
        temporaryFail: colors.ye40,
        accepted: colors.gr40,
        openRate: colors.ir40,
        delivered: colors.cy40,
        inbox: colors.bl60,
        clickedRate: colors.bl50,
        bounced: colors.bl40,
        hardBounces: colors.mj60,
        complainedRate: colors.mj40,
        softBounced: colors.pi40,
        retrying: colors.pi60
    });
export default colorsVariationsFactory;
