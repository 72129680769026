import styled from "styled-components";
import PropTypes from "prop-types";
const StyledImage = styled.div`
  display: inline-flex;
  ${({ theme, mt })=>mt && `margin-top: ${theme.sizes.margins[mt] || theme.sizes[mt] || mt}`};
`;
StyledImage.propTypes = {
    mt: PropTypes.string
};
export default StyledImage;
