const animations = {
    degrees: {
        d1: '45deg',
        d2: '90deg',
        d3: '180deg',
        d4: '360deg'
    },
    easing: {
        accelerate: 'cubic-bezier(0.4, 0.0, 1, 1)',
        decelerate: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
        standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)'
    },
    moves: {
        x1: '4px',
        x2: '8px',
        x3: '16px',
        x4: '32px',
        y1: '4px',
        y2: '8px',
        y3: '16px',
        y4: '32px'
    },
    velocities: {
        v1: '75ms',
        v2: '100ms',
        v3: '150ms',
        v4: '200ms',
        v5: '250ms',
        v6: '300ms',
        v7: '350ms',
        v8: '400ms',
        v9: '450ms',
        v10: '500ms'
    }
};
export default animations;
