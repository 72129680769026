function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
import Colr from "colr";
import { every, isNumber, isString, replace } from "lodash";
export const lightenDarkenColor = (hexColorString, percent)=>{
    const color = replace(hexColorString, '#', '');
    const num = parseInt(color, 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const B = (num >> 8 & 0x00ff) + amt;
    const G = (num & 0x0000ff) + amt;
    return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1)}`;
};
export const hexToRGB = (hexColorString, alpha)=>{
    const color = replace(hexColorString, '#', '');
    const r = parseInt(color.slice(0, 2), 16);
    const g = parseInt(color.slice(2, 4), 16);
    const b = parseInt(color.slice(4, 6), 16);
    if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
};
export const convertColorToStructure = (value, defaultValue = '000000')=>{
    if (!isString(value)) {
        return {
            hex: value.toHex(),
            rgb: value.toRgbObject(),
            hsl: value.toHslObject(),
            hsv: value.toHsvObject()
        };
    }
    const colr = new Colr();
    const defaultColor = Colr.fromHex(defaultValue);
    let color = {
        hex: defaultValue,
        rgb: defaultColor.toRgbObject(),
        hsl: defaultColor.toHslObject(),
        hsv: defaultColor.toHsvObject()
    };
    if (value) {
        if (value === 'transparent') {
            color.rgb.a = color.hsl.a = color.hsv.a = 0;
            return color;
        }
        const rgb = /^rgb\((\d+)\D+(\d+)\D+(\d+)\)$/.exec(value);
        if (rgb) {
            const r = parseInt(rgb[1], 10);
            const g = parseInt(rgb[2], 10);
            const b = parseInt(rgb[3], 10);
            const fromRgb = colr.fromRgb(r, g, b);
            color = {
                hex: fromRgb.toHex(),
                rgb: fromRgb.toRgbObject(),
                hsl: fromRgb.toHslObject(),
                hsv: fromRgb.toHsvObject()
            };
        }
        if (value.charAt(0) === '#') {
            if (value.length < 4) {
                color.hex = value;
            } else {
                let fromHex;
                try {
                    fromHex = colr.fromHex(value);
                } catch (e) {
                    value = color.hex;
                    fromHex = colr.fromHex(value);
                }
                color = {
                    hex: value,
                    rgb: fromHex.toRgbObject(),
                    hsl: fromHex.toHslObject(),
                    hsv: fromHex.toHsvObject()
                };
            }
        }
        color.rgb.a = color.hsl.a = color.hsv.a = 1;
    }
    return color;
};
export const luminance = (r, g, b)=>{
    const RGBArray = [
        r,
        g,
        b
    ];
    const areAllNumbers = every(RGBArray, (v)=>isNumber(v));
    if (!areAllNumbers) {
        return 1;
    }
    const a = RGBArray.map((v)=>{
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};
export const getLuminanceRatio = (lightLuminance, darkLuminance)=>lightLuminance > darkLuminance ? (darkLuminance + 0.05) / (lightLuminance + 0.05) : (lightLuminance + 0.05) / (darkLuminance + 0.05);
export const shouldReturnWhiteOrBlackText = (backgroundColor)=>{
    const whiteRGB = parseRGB(hexToRGB(backgroundColor, undefined));
    const lightLuminance = luminance(whiteRGB.r, whiteRGB.g, whiteRGB.b);
    let blackRGB = parseRGB(hexToRGB('#000000', undefined));
    let darkLuminance = luminance(blackRGB.r, blackRGB.g, blackRGB.b);
    const ratioLight = getLuminanceRatio(lightLuminance, darkLuminance);
    blackRGB = parseRGB(hexToRGB('#ffffff', undefined));
    darkLuminance = luminance(blackRGB.r, blackRGB.g, blackRGB.b);
    const ratioDark = getLuminanceRatio(lightLuminance, darkLuminance);
    return ratioDark < ratioLight ? '#ffffff' : '#000000';
};
export const parseRGB = (rgb)=>{
    const regex = /(\d{1,3})/g;
    const matchedValue = rgb == null ? void 0 : rgb.match(regex);
    return matchedValue && matchedValue.length >= 3 ? {
        r: parseInt(matchedValue[0]),
        g: parseInt(matchedValue[1]),
        b: parseInt(matchedValue[2])
    } : rgb;
};
const adjustLightnessToHSLColorAndConvertToHex = (hslColor, lightness)=>Colr.fromHslObject(_extends({}, hslColor, {
        l: hslColor.l + lightness
    })).toHex();
export const convertHexToResellerThemeShades = (resellerHexColor)=>{
    const resellerHslColor = Colr.fromHex(resellerHexColor).toHslObject();
    return {
        pw10: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 71),
        pw20: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 60),
        pw30: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 44),
        pw40: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 26),
        pw50: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 11),
        pw60: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 0),
        pw70: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, -10),
        pw80: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, -19),
        bee1: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 0),
        bee2: lightenDarkenColor(resellerHexColor, 5),
        lagoon: adjustLightnessToHSLColorAndConvertToHex(resellerHslColor, 0),
        lightlagoon: lightenDarkenColor(resellerHexColor, 10)
    };
};
