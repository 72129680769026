import { css } from "styled-components";
import { display, margins, paddings, sizes } from "../../../helpers";
export default css`
  ${display};
  ${margins};
  ${paddings};
  ${sizes};

  text-align: ${({ textAlign })=>textAlign || 'inherit'};
`;
