import styled from "styled-components";
import { Body2Medium } from "../../Typography";
import genericButtonStyle from "../helpers/genericButtonStyle.js";
const DropdownButton = styled(Body2Medium.withComponent('button'))`
  ${genericButtonStyle};

  color: ${({ theme })=>theme.components.button.dropdown.text};
  fill: ${({ theme })=>theme.components.button.dropdown.text};
  background-color: ${({ theme })=>theme.components.button.dropdown.background};
  box-sizing: border-box;
  border: ${({ theme })=>`${theme.globals.borderWidth.bW1} solid ${theme.components.button.dropdown.border}`};

  padding: ${({ $small, theme })=>`${$small ? theme.sizes.s2 : theme.sizes.s3} ${$small ? theme.sizes.s3 : theme.sizes.s4}`};

  &:hover:not(.is-loading):not(.is-disabled):not(:active):not(:focus) {
    color: ${({ theme })=>theme.components.button.dropdown.textHover};
    fill: ${({ theme })=>theme.components.button.dropdown.textHover};
    background-color: ${({ theme })=>theme.components.button.dropdown.backgroundHover};
    border-color: ${({ theme })=>theme.components.button.dropdown.borderHover};
  }

  &:active:not(.is-disabled):not(.is-loading) {
    color: ${({ theme })=>theme.components.button.dropdown.textPressed};
    fill: ${({ theme })=>theme.components.button.dropdown.textPressed};
    background-color: ${({ theme })=>theme.components.button.dropdown.backgroundPressed};
    border-color: ${({ theme })=>theme.components.button.dropdown.borderPressed};
  }

  &:focus:not(:active):not(.is-loading) {
    background-color: ${({ theme })=>theme.components.button.dropdown.backgroundFocus};
    color: ${({ theme })=>theme.components.button.dropdown.textFocus};
    fill: ${({ theme })=>theme.components.button.dropdown.textFocus};
    border-color: ${({ theme })=>theme.components.button.dropdown.borderFocus};
  }

  &.is-loading {
    color: ${({ theme })=>theme.components.button.dropdown.backgroundLoading};
    fill: ${({ theme })=>theme.components.button.dropdown.backgroundLoading};
    background-color: ${({ theme })=>theme.components.button.dropdown.backgroundLoading};

    // Loader colors.
    > * {
      border-color: ${({ theme })=>theme.components.button.dropdown.loader};
      border-top-color: ${({ theme })=>theme.components.button.dropdown.backgroundLoading};
    }
  }
`;
export default DropdownButton;
