const colors = {
    lightmustard: '#F7A233',
    lightgrass: '#4DBC5C',
    lightblood: '#F86469',
    lightelectric: '#309CED',
    steel: '#53555D',
    snow: '#FFFFFF',
    coolgrey: '#FCFCFC',
    lightsmoke: '#F2F2F2',
    grey: '#888888',
    lagoon: '#195A6A',
    lakeBlue: '#79CAB6',
    purple: '#9B59B6',
    purpler: '#797BCA',
    bee1: '#195A6A',
    violet: '#9585F4',
    greyscale: '#95A5A6',
    marine: '#3E8AEE',
    applegreen: '#91CB3E',
    blueE: '#7189FF',
    cyan: '#FF30FF',
    dribble: '#EA4C89',
    facebook: '#3B5998',
    firefighter: '#E74C3C',
    flickr: '#FF0084',
    github: '#4078C0',
    googleplus: '#DD4B39',
    html: '#58AAE1',
    instagram: '#405DE6',
    linkedin: '#0077B5',
    medium: '#00AB6C',
    mjml: '#FF624D',
    orange: '#F39011',
    pink: '#FE30FE',
    pinkE: '#F374AE',
    pinterest: '#BD081C',
    snapchat: '#FFFC00',
    soundcloud: '#FF8800',
    tumblr: '#35465C',
    threads: '#000000',
    twitter: '#1DA1F2',
    twitterX: '#0F141A',
    vimeo: '#1AB7EA',
    violette: '#758ECD',
    youtube: '#FF0000',
    xing: '#CFDC00',
    transparent: 'transparent',
    softmustard: '#FDECD6',
    softgrass: '#DBF2DE',
    softblood: '#FEE0E1',
    softelectric: '#D6EBFB',
    statusblocked: '#000000',
    statusclicked: '#56a003',
    statusdelivered: '#B7B7B7',
    statusexcluded: '#FF624D',
    statushardbounced: '#ff8a6b',
    statusopened: '#7DD221',
    statusretrying: '#8C8C8C',
    statussent: '#8085e9',
    statussoftbounced: '#ffbc97',
    statusspam: '#cc0b24',
    statustotal: '#8C8C8C',
    statussubscribed: '#91CB3E',
    statusunsubscribed: '#9B59B6',
    mg10: '#FFF3F0',
    mg20: '#FFDFC6',
    mg30: '#F99A94',
    mg40: '#F06B66',
    mg50: '#CB4A49',
    mg60: '#9B3533',
    mg70: '#6B2628',
    mg80: '#3C171B',
    mj10: '#F9F5FF',
    mj20: '#E5D9FF',
    mj30: '#BBAAF7',
    mj40: '#9585F4',
    mj50: '#6F67D9',
    mj60: '#4B47C1',
    mj70: '#30308A',
    mj80: '#1D204B',
    ir10: '#E9FCF4',
    ir20: '#B0FADC',
    ir30: '#56DA9E',
    ir40: '#25A877',
    ir50: '#098761',
    ir60: '#086546',
    ir70: '#0A4731',
    ir80: '#092A1D',
    pw10: '#F5F8FA',
    pw20: '#D0E0E6',
    pw30: '#97C0CC',
    pw40: '#5F98A9',
    pw50: '#377386',
    pw60: '#195A6A',
    pw70: '#03414E',
    pw80: '#002025',
    neutral0: '#FFFFFF',
    neutral10: '#F5F6F6',
    neutral20: '#E3E7E9',
    neutral30: '#B3BDC4',
    neutral40: '#8796A0',
    neutral50: '#63737E',
    neutral60: '#46545C',
    neutral70: '#2D3941',
    neutral80: '#131B20',
    or10: '#FFF5EC',
    or20: '#FFDFC6',
    or30: '#F9A685',
    or40: '#E37452',
    or50: '#BC5A3E',
    or60: '#8B4331',
    or70: '#602E24',
    or80: '#3B1C18',
    ye10: '#FFF9E4',
    ye20: '#FDEAB6',
    ye30: '#EEBF61',
    ye40: '#BE8E2F',
    ye50: '#98712D',
    ye60: '#725428',
    ye70: '#503B20',
    ye80: '#2E2317',
    gr10: '#EFFDE5',
    gr20: '#D4F9B4',
    gr30: '#A1D863',
    gr40: '#70A328',
    gr50: '#538300',
    gr60: '#426203',
    gr70: '#314307',
    gr80: '#212808',
    cy10: '#EAF8FF',
    cy20: '#C1ECFD',
    cy30: '#61D0EE',
    cy40: '#20A2C1',
    cy50: '#11819D',
    cy60: '#076173',
    cy70: '#06434C',
    cy80: '#06292C',
    bl10: '#F4F7FF',
    bl20: '#D3E1FF',
    bl30: '#93B8F7',
    bl40: '#5F94E7',
    bl50: '#3B76CB',
    bl60: '#285896',
    bl70: '#1C3F66',
    bl80: '#112437',
    pi10: '#FFF3FB',
    pi20: '#FFD1F0',
    pi30: '#F098DD',
    pi40: '#D76EC7',
    pi50: '#BD43B5',
    pi60: '#8D377C',
    pi70: '#622859',
    pi80: '#351B38'
};
export default colors;
