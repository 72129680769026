// Track CTA click event for GA4
const trackClick = ({
  // Default event name can be customized using following eventName parameter
  eventName = 'marketing_cta_click',
  // Will pass extra parameter to GA4. Since 'to' will be null it helps to identify which file is downloaded
  fileName,
  text,
  to,
}) => {
  // Make sure to only run that client side when gtag is available
  if (typeof window === 'undefined' || !window?.gtag) return
  window.gtag('event', eventName, {
    ...(fileName ? { fileName } : {}),
    from: location.href,
    to,
    text,
  })
}

export default trackClick
