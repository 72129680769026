import styled, { css } from "styled-components";
import { Body2Medium } from "../../Typography";
import genericButtonStyle from "../helpers/genericButtonStyle.js";
const buttonStyle = ({ theme })=>{
    const { background, backgroundHover, backgroundPressed, loader, text, hidden } = theme.components.button.upgrade;
    return css`
    background-color: ${background};
    color: ${text};

    &:hover:not(.is-loading):not(.is-disabled) {
      background-color: ${backgroundHover};
    }

    &:active:not(.is-disabled):not(.is-loading) {
      background-color: ${backgroundPressed};
    }

    &.is-loading {
      color: ${hidden};

      // Loader colors.
      > * {
        border-color: ${loader};
        border-top-color: ${hidden};
      }
    }
  `;
};
const UpgradeButton = styled(Body2Medium.withComponent('button'))`
  ${genericButtonStyle};
  ${(props)=>buttonStyle(props)};
`;
export default UpgradeButton;
