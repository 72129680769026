import styled, { css } from 'styled-components'
import { map } from 'lodash'

import ExternalLinkSVG from '@icons/ExternalLinkSVG'
import { fadeInDownAnim } from '@styles/Animations'
import {
  styleForDesktop,
  styleForTablet,
  styleForTabletAndMobile,
  styleForMobile,
} from '@utils'

import { RightPart } from './RightPart'
import { StyledMenuPanel, StyledMenuPanelTabletStep2 } from './StyledMenuPanel'
import { ColumnLargeDropdown, SubMenuCard } from './SubPanelElement'

// The main logo
export const LogoBrand = styled.div`
  display: flex;
  * {
    height: ${({ theme }) => theme.sizes.s6};
  }
`

// The entire menu
const StyledMenu = styled.div`
  ${({
    isLarge,
    isOpen,
    isTopMenuPanelOpen,
    selectedPanel = 'subpanel-mainMenu-999',
    theme,
  }) => css`
    background-color: ${theme.colors.neutral0};
    border-bottom: solid ${theme.colors.neutral20} ${theme.sizes.s0};
    box-shadow: 0 ${theme.sizes.s1} ${theme.sizes.s3} -${theme.sizes.s1} ${
      theme.colors.neutral100
    }29;
    position: fixed;
    top: ${isLarge ? theme.sizes.s6 : `0;`};
    transition: top 250ms ease-out;
    width: 100%;
    z-index: ${isOpen ? 7000 : isLarge ? 5000 : 7000};
    
    ${ExternalLinkSVG} {
      height: ${theme.sizes.s4};
      margin-left: ${theme.sizes.s2};
      width: ${theme.sizes.s4};
      
      & * {
        fill: ${theme.colors.neutral40};
      }
    }
    
    ${styleForDesktop} {
      .subpanel-${selectedPanel} {
        ${isOpen ? `display: flex;` : `display: none;`}
        ${isOpen && !isLarge && `top: ${theme.sizes.menuScrolledHeight};`}

        // Animations ---
        ${fadeInDownAnim(isOpen)};

        ${map(
          Array(10),
          (_child, index) => css`
            & > ${ColumnLargeDropdown}:nth-child(${index + 1}),
            & > ${SubMenuCard}:nth-child(${index + 1}) {
              ${fadeInDownAnim(isOpen, 80 + 40 * (index + 1))}
            }
          `,
        )}
        // ---
      }

      ${
        isOpen &&
        `
        .menubutton-${selectedPanel} {
          &:after {
            transform: scaleX(1);
          }

          svg * {
            fill: ${theme.colors.neutral50};
            stroke: ${theme.colors.neutral50};
          }
        }
      `
      }
    }

    ${styleForTabletAndMobile} {
      ${LogoBrand} {
        order: 2;
      }
    
      ${RightPart} {
        order: 3;
      }
      
      ${StyledMenuPanelTabletStep2} {
        overflow: hidden auto;
        scrollbar-width: thin;
      }
    }

    ${styleForMobile} {
      top: 0;
    }
  
    ${StyledMenuPanel} {
      ${styleForTablet} {
        display: ${isOpen && !isTopMenuPanelOpen ? 'flex' : 'none'};
      }

      ${styleForMobile} {
        display: ${isOpen ? 'flex' : 'none'};
      }
    }
  `}
`

export default StyledMenu
