import styled from "styled-components";
import { resetCSS } from "../../../helpers";
import commonStyle from "../helpers/commonStyle.js";
const Body2 = styled.p.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme })=>color ? theme.colors[color] || color : theme.components.typography.body2.text};
  font-family: ${({ theme })=>theme.fonts.body2.fontFamily};
  font-size: ${({ theme })=>theme.fonts.body2.fontSize};
  font-weight: ${({ theme })=>theme.fonts.body2.fontWeight};
  line-height: ${({ theme })=>theme.fonts.body2.lineHeight};
`;
export default Body2;
