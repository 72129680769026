import styled, { css } from 'styled-components'
import { scaleUp } from '@styles/Animations'

const ImageDiv = styled.div`
  ${({
    bgColor = 'transparent',
    displayWidth,
    href,
    ratioWidthHeight,
    theme,
  }) => css`
    align-items: center;
    // Default background is transparent
    background-color: ${bgColor};
    display: flex;
    max-width: 100%;
    max-width: -moz-available;
    max-width: -webkit-fill-available;
    overflow: hidden;
    // Keeps enough space for the image to lazy load without causing 'screen jump'

    aspect-ratio: ${ratioWidthHeight};
    transition: 150ms -webkit-filter linear;

    &:hover {
      ${href &&
      css`
        cursor: pointer;
        ${scaleUp()}
      `}
    }

    &[data-is-loaded='true'] {
      filter: none;
    }

    @media screen and (max-width: ${displayWidth}) {
      padding-top: 0;
    }

    position: relative;
    // displayWidth create design issue with small mobile size.
    width: ${displayWidth || '100%'};

    img {
      @media screen and (max-width: ${displayWidth}) {
        position: relative;
      }
      position: absolute;
      top: 0;
      width: 100%;
    }
  `}
`

export default ImageDiv
