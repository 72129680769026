import styled from "styled-components";
import PropTypes from "prop-types";
import { margins, paddings, resetCSS } from "../helpers";
const StyledIcon = styled('svg').attrs(resetCSS)`
  ${margins};
  ${paddings};
  ${({ color, size, theme })=>`
    fill: ${color ? theme.colors[color] || color : 'inherit'};
    height: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    min-height: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    min-width: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    max-height: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    max-width: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    width: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
  `}
`;
StyledIcon.defaultProps = {
    deletionButton: false,
    size: 's4'
};
StyledIcon.propTypes = {
    onClick: PropTypes.func,
    size: PropTypes.string
};
export default StyledIcon;
