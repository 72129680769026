import { hexToRGB, lightenDarkenColor } from "../../helpers/color.js";
import { globals } from ".";
const components = ({ colors: c, globals: g = globals })=>({
        accordion: {
            background: c.neutral0,
            backgroundHover: c.neutral10,
            backgroundPressed: c.neutral0,
            border: c.neutral20,
            icon: c.neutral70,
            iconPressed: c.pw60,
            iconLocked: c.neutral70,
            label: c.neutral70,
            labelPressed: c.pw60
        },
        alert: {
            backgroundColor: {
                warning: c.lightmustard,
                critical: c.lightblood
            },
            text: {
                color: c.snow
            }
        },
        avatar: {
            border: c.darksteel,
            text: c.snow
        },
        backToTop: {
            color: c.darkgrey,
            colorActive: c.darkgrey,
            colorHover: c.darkgrey,
            background: c.snow,
            backgroundActive: c.darksmoke,
            backgroundPressed: c.darksmoke,
            backgroundHover: c.lightsmoke
        },
        banner: {
            backgroundColor: {
                info: c.softelectric,
                error: c.softblood,
                notice: c.mj10,
                success: c.softgrass,
                tips: c.coolgrey,
                warning: c.softmustard
            },
            color: {
                icon: c.darkgrey
            },
            button: {
                border: c.grey,
                background: c.transparent,
                backgroundHover: hexToRGB(c.grey, 0.1),
                backgroundActive: hexToRGB(c.grey, 0.2),
                color: c.darkgrey
            }
        },
        box: {
            border: c.smoke
        },
        breadcrumb: {
            link: {
                color: c.neutral40,
                hover: c.pw50,
                active: c.pw60
            },
            chevron: {
                color: c.neutral40,
                active: c.pw60
            }
        },
        button: {
            dropdown: {
                background: c.transparent,
                backgroundFocus: c.transparent,
                backgroundHover: c.transparent,
                backgroundLoading: c.transparent,
                backgroundPressed: c.transparent,
                border: c.neutral40,
                borderHover: c.neutral60,
                borderFocus: c.pw60,
                borderPressed: c.pw60,
                icon: c.neutral60,
                loader: c.neutral40,
                text: c.neutral40,
                textFocus: c.neutral60,
                textHover: c.neutral60,
                textPressed: c.neutral60
            },
            icon: {
                deletion: {
                    text: c.snow,
                    background: c.transparent,
                    textHover: c.snow,
                    backgroundHover: c.lightblood,
                    colorPressed: c.snow,
                    backgroundPressed: lightenDarkenColor(c.lightblood, 5)
                },
                background: c.transparent,
                backgroundFocus: c.pw20,
                backgroundHover: c.neutral10,
                backgroundLoading: c.neutral20,
                backgroundPressed: c.pw20,
                border: c.neutral70,
                loader: c.neutral70,
                text: c.neutral60,
                textFocus: c.neutral70,
                textHover: c.neutral70,
                textPressed: c.neutral70
            },
            primary: {
                background: c.pw60,
                backgroundFocus: c.pw50,
                backgroundHover: c.pw50,
                backgroundLoading: c.pw60,
                backgroundPressed: c.pw70,
                borderFocusWidth: g.borderWidth.borderWidth0,
                borderHoverWidth: g.borderWidth.borderWidth0,
                borderPressedWidth: g.borderWidth.borderWidth0,
                borderWidth: g.borderWidth.borderWidth0,
                hidden: c.transparent,
                loader: c.neutral0,
                shadowPressed: `0 0 1px 2px ${c.pw30}`,
                text: c.neutral0,
                textFocus: c.neutral0,
                textHover: c.neutral0,
                textPressed: c.neutral0
            },
            deletion: {
                background: c.lightblood,
                backgroundHover: lightenDarkenColor(c.lightblood, 5),
                backgroundLoading: lightenDarkenColor(c.lightblood, 10),
                backgroundPressed: lightenDarkenColor(c.lightblood, 10),
                borderWidth: g.borderWidth.borderWidth0,
                borderHoverWidth: g.borderWidth.borderWidth0,
                borderFocusWidth: g.borderWidth.borderWidth0,
                borderPressedWidth: g.borderWidth.borderWidth0,
                hidden: c.transparent,
                loader: c.snow,
                text: c.snow,
                textFocus: c.snow,
                textHover: c.snow,
                textPressed: c.snow
            },
            secondary: {
                background: c.transparent,
                backgroundFocus: hexToRGB(c.bee1, 0.2),
                backgroundHover: hexToRGB(c.bee1, 0.1),
                backgroundLoading: hexToRGB(c.bee1, 0.2),
                backgroundPressed: hexToRGB(c.bee1, 0.2),
                borderColor: c.bee1,
                borderFocusColor: c.bee1,
                borderFocusWidth: g.borderWidth.borderWidth1,
                borderHoverColor: c.bee1,
                borderHoverWidth: g.borderWidth.borderWidth1,
                borderLoadingColor: c.bee3,
                borderLoadingWidth: g.borderWidth.borderWidth1,
                borderPressedColor: c.bee1,
                borderPressedWidth: g.borderWidth.borderWidth1,
                borderWidth: g.borderWidth.borderWidth1,
                hidden: c.transparent,
                loader: c.bee3,
                text: c.bee1,
                textFocus: c.bee1,
                textHover: c.bee1,
                textPressed: c.bee1,
                neutral: {
                    background: c.lightsmoke,
                    backgroundFocus: c.darksmoke,
                    backgroundHover: c.smoke,
                    backgroundLoading: c.lightsmoke,
                    backgroundPressed: c.darksmoke,
                    borderColor: c.transparent,
                    borderFocusColor: c.transparent,
                    borderHoverColor: c.transparent,
                    borderLoadingColor: c.transparent,
                    borderPressedColor: c.transparent,
                    loader: c.darkgrey,
                    text: c.darkgrey,
                    textFocus: c.darkgrey,
                    textHover: c.darkgrey,
                    textPressed: c.darkgrey
                },
                outlineDark: {
                    background: c.transparent,
                    backgroundFocus: c.smoke,
                    backgroundHover: c.lightsmoke,
                    backgroundLoading: c.lightsmoke,
                    backgroundPressed: c.smoke,
                    borderColor: c.darkgrey,
                    borderFocusColor: c.darkgrey,
                    borderHoverColor: c.darkgrey,
                    borderLoadingColor: c.darkgrey,
                    borderPressedColor: c.darkgrey,
                    loader: c.darkgrey,
                    text: c.darkgrey,
                    textFocus: c.darkgrey,
                    textHover: c.darkgrey,
                    textPressed: c.darkgrey
                }
            },
            tertiary: {
                background: c.transparent,
                backgroundFocus: c.darksmoke,
                backgroundHover: c.lightsmoke,
                backgroundLoading: c.darksmoke,
                backgroundPressed: c.darksmoke,
                border: c.darkgrey,
                hidden: c.transparent,
                loader: c.darkgrey,
                text: c.grey,
                textFocus: c.darkgrey,
                textHover: c.darkgrey,
                textPressed: c.darkgrey
            },
            toggle: {
                background: c.neutral0,
                backgroundActive: c.pw20,
                backgroundFocus: c.pw20,
                backgroundHover: c.neutral20,
                backgroundPressed: c.pw20,
                border: c.neutral40,
                borderActive: c.pw60,
                borderFocus: c.pw60,
                borderHover: c.neutral60,
                borderPressed: c.pw60,
                icon: c.neutral50,
                iconActive: c.pw60,
                iconFocus: c.pw60,
                iconHover: c.neutral80,
                iconPressed: c.pw60,
                text: c.neutral50,
                textActive: c.pw60,
                textFocus: c.pw60,
                textHover: c.neutral80,
                textPressed: c.pw60
            },
            upgrade: {
                background: c.mg40,
                backgroundHover: c.mg30,
                backgroundPressed: c.mg50,
                loader: c.snow,
                text: c.snow,
                hidden: c.transparent
            }
        },
        buttonDropdown: {
            primary: {
                icon: c.neutral0,
                divider: c.neutral0
            },
            secondary: {
                icon: c.bee1
            }
        },
        calendar: {
            borderColor: c.lightsmoke,
            boxShadowColor: hexToRGB(c.neutral40, 0.1),
            dayHeader: {
                color: c.lagoon
            },
            cell: {
                disabled: {
                    background: c.smoke,
                    color: c.neutral30,
                    hovered: {
                        background: c.neutral0,
                        color: c.neutral30,
                        border: c.neutral0
                    }
                },
                hovered: {
                    background: c.pw20,
                    color: c.neutral80
                },
                selected: {
                    background: c.lagoon,
                    color: c.snow
                },
                selectedStart: {
                    border: c.lagoon,
                    color: c.neutral0
                },
                selectedEnd: {
                    background: c.pw60,
                    color: c.snow
                },
                selectedInRange: {
                    background: c.pw20,
                    color: c.neutral80
                },
                today: {
                    color: c.lagoon,
                    border: c.mj30,
                    background: c.snow,
                    hovered: {
                        background: c.lightlagoon,
                        color: c.snow,
                        border: c.transparent
                    }
                },
                prevNextMonth: {
                    background: c.transparent,
                    color: c.neutral30,
                    hovered: {
                        background: c.coolgrey,
                        color: c.grey
                    }
                },
                default: {
                    background: c.transparent,
                    color: c.neutral80,
                    hovered: {
                        background: c.pw40,
                        color: c.neutral80
                    }
                }
            },
            header: {
                color: c.darkgrey
            },
            time: {
                borderColor: c.smoke
            }
        },
        checkbox: {
            background: c.neutral0,
            backgroundChecked: c.pw50,
            backgroundFocusChecked: c.pw60,
            backgroundWrapper: c.transparent,
            border: c.neutral50,
            borderChecked: c.pw60,
            borderError: c.lightblood,
            borderFocus: c.pw50,
            borderFocusChecked: c.pw60,
            borderHover: c.neutral60,
            borderHoverChecked: c.pw60,
            borderWidth: g.borderWidth.borderWidth1,
            borderFocusWidth: g.borderWidth.borderWidth1,
            checkmarkColor: c.neutral0
        },
        codeEditor: {
            iconCopy: c.grey,
            iconCopyHover: c.darkgrey,
            badge: {
                background: c.transparent,
                backgroundActive: hexToRGB(c.lagoon, 0.2),
                backgroundHover: c.transparent,
                border: c.cloudgrey,
                borderActive: c.transparent,
                borderHover: c.lagoon,
                color: c.cloudgrey,
                colorActive: c.lagoon,
                colorHover: c.cloudgrey
            }
        },
        colorPicker: {},
        datatable: {
            borderColor: c.neutral20,
            backgroundColorRowChecked: c.pw30,
            controls: {
                background: c.neutral0
            },
            pagination: {
                backgroundSelected: c.pw20,
                colorSelected: c.pw60
            }
        },
        datePicker: {
            calendar: {
                date: {
                    backgroundFocus: c.lagoon,
                    backgroundHover: c.lightsmoke,
                    backgroundSelected: c.softlagoon,
                    borderFocus: c.lagoon,
                    borderHover: c.smoke,
                    borderSelected: hexToRGB(c.lagoon, 0.4),
                    colorFocus: c.snow,
                    textDisabled: c.lightgrey,
                    text: c.darkgrey,
                    textFocus: c.snow
                },
                header: {
                    background: c.coolgrey,
                    border: c.grey,
                    text: c.darkgrey
                },
                icon: {
                    background: c.transparent,
                    backgroundFocus: c.smoke,
                    backgroundHover: c.lightsmoke,
                    backgroundPressed: c.smoke,
                    textFocus: c.darkgrey,
                    textHover: c.darkgrey,
                    textPressed: c.darkgrey
                },
                subHeader: {
                    text: c.grey
                }
            },
            input: {
                background: c.snow,
                border: c.grey,
                borderFocus: c.lagoon,
                icon: c.darkgrey,
                iconFocus: c.lagoon,
                placeholder: c.lightgrey,
                text: c.darkgrey
            }
        },
        dropFileBox: {
            error: c.lightblood,
            hover: c.lagoon,
            default: c.darkgrey
        },
        input: {
            background: c.neutral0,
            border: c.neutral40,
            borderError: c.lightblood,
            borderFocus: c.pw60,
            borderHover: c.neutral60,
            icon: c.neutral60,
            iconCopy: c.neutral40,
            iconCopyHover: c.neutral60,
            loader: c.darkgrey,
            placeholder: c.neutral40,
            text: c.neutral60
        },
        inputFile: {
            errorIcon: c.lightblood,
            errorText: c.lightblood
        },
        inputNumber: {
            background: c.neutral0,
            border: c.neutral40,
            borderError: c.mg40,
            borderFocus: c.pw60,
            borderHover: c.neutral60,
            iconContainer: {
                icon: c.neutral40,
                iconHover: c.neutral60,
                iconFocus: c.neutral60,
                backgroundHover: c.neutral0,
                backgroundActive: c.neutral0,
                borderColor: c.neutral40
            },
            placeholder: c.neutral40,
            text: c.neutral60
        },
        inputDate: {
            backgroundColor: c.neutral0,
            border: c.neutral40,
            borderError: c.lightblood,
            borderFocus: c.pw60,
            icon: c.neutral60
        },
        label: {
            background: c.lightsmoke,
            backgroundHover: c.smoke,
            icon: c.grey,
            iconHover: c.darkgrey,
            text: c.darkgrey,
            textHover: c.darkgrey
        },
        labelledWrapper: {
            notification: {
                text: c.darkgrey,
                textError: c.lightblood
            }
        },
        link: {
            border: c.neutral20,
            borderVisited: c.pw50,
            text: c.pw60,
            textHover: c.pw50,
            textPressed: c.pw70,
            textFocus: c.pw70,
            textVisited: c.pw60,
            textDecoration: 'underline',
            textDecorationHover: 'underline'
        },
        linkButton: {
            backgroundColor: c.transparent,
            border: c.pw30,
            borderFocus: c.neutral20,
            icon: c.pw60,
            iconHover: c.pw50,
            iconFocus: c.pw70,
            text: c.pw60,
            textActive: c.pw70,
            textHover: c.pw50
        },
        loader: {
            background: c.transparent,
            color: c.pw60,
            colorSecondary: c.pw60
        },
        modal: {
            body: {
                background: c.snow
            },
            content: {
                borderBottom: c.lightsmoke
            },
            header: {
                background: c.coolgrey,
                border: c.smoke,
                text: c.darkgrey
            },
            overlay: {
                color: c.overlay
            }
        },
        optionPanel: {
            divider: {
                color: c.smoke
            },
            option: {
                background: c.snow,
                backgroundActive: hexToRGB(c.pw60, 0.1),
                backgroundFocus: hexToRGB(c.pw60, 0.1),
                backgroundHover: hexToRGB(c.pw60, 0.1),
                backgroundSelected: hexToRGB(c.pw60, 0.1),
                borderFocus: c.pw60,
                text: c.neutral60,
                textHover: c.neutral60
            },
            deletion: {
                icon: {
                    color: c.lightblood,
                    colorHover: c.snow
                },
                background: c.snow,
                backgroundHover: c.lightblood,
                text: c.lightblood,
                textHover: c.snow
            },
            title: {
                text: c.darkgrey
            }
        },
        pagination: {
            icon: {
                color: c.grey
            },
            page: {
                selected: c.lagoon
            }
        },
        phoneNumber: {
            error: {
                icon: c.mg40,
                text: c.mg40
            },
            input: {
                placeholder: c.neutral40,
                text: c.neutral60,
                background: c.neutral0
            },
            borderer: {
                border: c.neutral40,
                borderError: c.mg40,
                borderFocus: c.pw60
            }
        },
        progressBar: {
            background: c.smoke,
            backgroundFilled: c.or40,
            backgroundProgress: c.pw60
        },
        roundNotification: {
            info: {
                color: c.lightelectric
            },
            success: {
                color: c.lightgrass
            },
            warning: {
                color: c.lightmustard
            }
        },
        roundSelector: {
            background: c.snow
        },
        searchBar: {
            border: c.neutral40,
            background: c.neutral0,
            icon: c.neutral60,
            borderHover: c.neutral60,
            backgroundHover: c.neutral0,
            iconHover: c.neutral60,
            borderFocus: c.pw60,
            backgroundFocus: c.neutral0,
            iconFocus: c.neutral60,
            text: c.neutral60,
            placeholder: c.neutral40,
            loader: c.neutral40,
            iconClear: c.neutral40,
            iconClearHover: c.neutral60
        },
        select: {
            background: c.transparent,
            backgroundHover: c.transparent,
            backgroundFocus: c.transparent,
            border: c.neutral40,
            borderError: c.mg40,
            borderHover: c.neutral60,
            borderFocus: c.pw60,
            innerLabel: c.neutral40,
            icon: c.neutral60,
            loader: c.darkgrey,
            placeholder: c.neutral30,
            selectedText: c.neutral60,
            text: c.neutral40
        },
        scrollbar: {
            background: c.scrollbarlight,
            backgroundHover: c.scrollbarlightHover
        },
        skeleton: {
            background: c.lightsmoke,
            backgroundStart: c.lightsmoke,
            backgroundEnd: c.smoke
        },
        slider: {
            trackBackground: c.smoke,
            trackFilled: c.pw60,
            thumb: c.pw60,
            thumbBorder: c.snow,
            thumbShadow: hexToRGB('#000000', 0.2)
        },
        stepper: {
            border: c.smoke,
            icon: {
                empty: c.grey,
                error: c.lightblood,
                success: c.lightgrass,
                warning: c.lightmustard
            },
            subtitle: c.grey
        },
        switch: {
            backgroundOn: c.pw60,
            backgroundOff: c.neutral20
        },
        tabs: {
            background: c.snow,
            backgroundTabsMode: c.coolgrey,
            backgroundTabsModeFocus: c.lightsmoke,
            backgroundHover: c.lightsmoke,
            border: c.smoke,
            indicator: c.lagoon,
            indicatorTabsMode: c.snow,
            loader: c.lagoon,
            text: c.grey,
            textActive: c.lagoon,
            textHover: c.darkgrey,
            backgroundDropdownActive: c.softlagoon
        },
        tag: {
            background: c.lightsmoke,
            icon: c.grey,
            iconHover: c.darkgrey,
            loader: c.pw60,
            loaderBackground: c.lightsmoke,
            text: c.darkgrey,
            textHover: c.darkgrey
        },
        textArea: {
            background: c.snow,
            border: c.grey,
            borderError: c.lightblood,
            borderFocus: c.lagoon,
            borderHover: c.darkgrey,
            placeholder: c.lightgrey,
            text: c.darkgrey
        },
        toast: {
            background: c.darkgrey,
            backgroundError: c.lightblood,
            closeIcon: c.snow,
            loader: c.snow,
            text: c.snow
        },
        toggle: {
            divider: {
                color: c.smoke
            }
        },
        tooltip: {
            background: c.blacksteel,
            text: c.snow
        },
        typography: {
            body1: {
                text: c.darkgrey
            },
            body1Medium: {
                text: c.darkgrey
            },
            body2: {
                text: c.darkgrey
            },
            body2Medium: {
                text: c.darkgrey
            },
            caption: {
                text: c.darkgrey
            },
            captionMedium: {
                text: c.darkgrey
            },
            header1: {
                text: c.darkgrey
            },
            header2: {
                text: c.darkgrey
            },
            header3: {
                text: c.darkgrey
            },
            header4: {
                text: c.darkgrey
            },
            header4Medium: {
                text: c.darkgrey
            },
            paragraph: {
                text: c.darkgrey
            },
            smallText: {
                text: c.darkgrey
            },
            smallTextBold: {
                text: c.darkgrey
            },
            subSubTitle: {
                text: c.darkgrey
            },
            subTitle: {
                text: c.darkgrey
            },
            text: {
                text: c.darkgrey
            },
            textLabel: {
                text: c.darkgrey
            },
            title: {
                text: c.darkgrey
            }
        }
    });
export default components;
