import { css } from "styled-components";
const displayMap = {
    b: 'block',
    ib: 'inline-block',
    if: 'inline-flex',
    f: 'flex'
};
const resolvedMap = {
    b: 'baseline',
    c: 'column',
    cr: 'column-reverse',
    ce: 'center',
    fe: 'flex-end',
    fs: 'flex-start',
    nw: 'nowrap',
    r: 'row',
    rr: 'row-reverse',
    s: 'stretch',
    sa: 'space-around',
    sb: 'space-between',
    se: 'space-evenly',
    w: 'wrap',
    wr: 'wrap-reverse'
};
export default (({ ac, ai, als, di, fb, fd, fg, fs, fw, jc, or })=>css`
  ${ac && `align-content: ${resolvedMap[ac] || ac}`};
  ${ai && `align-items: ${resolvedMap[ai] || ai}`};
  ${als && `align-self: ${resolvedMap[als] || als}`};
  ${di && `display: ${displayMap[di] || di}`};
  ${fb && `flex-basis: ${fb}`};
  ${fd && `flex-direction: ${resolvedMap[fd] || fd}`};
  ${fg && `flex-grow: ${fg}`};
  ${fs && `flex-shrink: ${fs}`};
  ${fw && `flex-wrap: ${resolvedMap[fw] || fw}`};
  ${jc && `justify-content: ${resolvedMap[jc] || jc}`};
  ${or && `order: ${or}`};
`);
