const responsive = {
    xs: {
        breakpoint: 0,
        containerMaxWidth: '420px',
        containerPadding: '0 s4',
        maxBreakpoint: null,
        width: null
    },
    sm: {
        breakpoint: 420,
        containerMaxWidth: '767px',
        containerPadding: '0 s5',
        maxBreakpoint: null,
        width: null
    },
    md: {
        breakpoint: 768,
        containerMaxWidth: '1023px',
        containerPadding: '0 s5',
        maxBreakpoint: null,
        width: null
    },
    lg: {
        breakpoint: 1024,
        containerMaxWidth: '1280px',
        containerPadding: '0 s5',
        maxBreakpoint: null,
        width: null
    }
};
export default responsive;
