import { useEffect } from "react";
import { useTheme } from "styled-components";
import injectStyleInHead from "./helpers/injectStyleInHead.js";
import resetNavStyle from "./styles/resetNavStyle.js";
import resetGlobalStyle from "./styles/resetGlobalStyle.js";
const GLOBAL_STYLE_ID = 'helmet-global-style';
const NAV_STYLE_ID = 'helmet-nav-style';
const HeadInjector = ()=>{
    const { globals = {} } = useTheme();
    const { global: globalStyle, nav } = globals == null ? void 0 : globals.cssClearer;
    useEffect(()=>{
        globalStyle && injectStyleInHead(resetGlobalStyle, GLOBAL_STYLE_ID);
        nav && injectStyleInHead(resetNavStyle, NAV_STYLE_ID);
    }, [
        globalStyle,
        nav
    ]);
    return null;
};
export default HeadInjector;
